export const generalAmenitiesOptions = [
  { key: 'cctv-monitoring', label: 'CCTV monitoring' },
  { key: 'cleaner', label: 'Cleaner' },
  { key: 'wheelchair-access', label: 'Disabled access' },
  { key: 'free-parking', label: 'Free Parking ' },
  { key: 'air-conditioning', label: 'Heating/Air conditioning' },
  { key: 'kitchen', label: 'Kitchen Facilities' },
  { key: 'key/electronic-access-card', label: 'Own Key / Electronic access card' },
  { key: 'pets-allowed', label: 'Pets Allowed' },
  { key: 'receptionist', label: 'Reception' },
  { key: 'secure-locker', label: 'Secure locker' },
  { key: 'toilet', label: 'Toilet / Washroom' },
  { key: 'wifi', label: 'WiFi' },
  { key: 'on-site-parking', label: 'On-site parking' },
  { key: 'street-parking', label: 'Street Parking' },
  { key: 'secure-overnight-storage', label: 'Secure overnight storage' },
  { key: 'step-free-access', label: 'Step-free access' },
  { key: 'drive-in', label: 'Drive-in' },
  { key: 'ramp-access', label: 'Ramp access' },
  { key: 'goods-lift', label: 'Goods lift' },
  { key: 'lift', label: 'Lift' },
  { key: 'lounge-area', label: 'Lounge area' },
  { key: 'windows', label: 'Windows' },
  { key: 'private-space', label: 'Private Space' },
  { key: 'shared-space', label: 'Shared Space' },
  { key: 'smoking-allowed', label: 'Smoking Allowed' },
  { key: 'children-allowed', label: 'Children Allowed' },
  { key: 'food-drink-allowed', label: 'Food/Drink Allowed' },
  { key: 'alcohol-allowed', label: 'Alcohol Allowed' },
  { key: 'host-present', label: 'Host Present' },
  { key: 'handwash-station', label: 'Handwash Station' },
  { key: 'loading-area', label: 'Loading Area' },
  { key: 'shelves', label: 'Shelves' },
  { key: 'bathroom', label: 'Bathroom' },
  { key: 'electricity', label: 'Electricity' },
  { key: 'ground-floor', label: 'Ground Floor' },
  { key: 'security-system', label: 'Security System' },
  { key: 'bins', label: 'Bins' },
  { key: '24-7-access', label: '24/7 Access' },
  { key: 'common-area', label: 'Common area' },
  { key: 'staff-kitchen', label: 'Staff Kitchen' },
  { key: 'computers-available', label: 'Computers Available' },
  { key: 'accomodation-available', label: 'Accomodation available' },
];

export const hairAndBeautyOptions = [
  { key: 'hair-stylist', label: 'Salon Chair', metaLabel: 'Salon Chair', labelImg: 'hairStylist' },
  { key: 'barber', label: 'Barber Chair', metaLabel: 'Barber Chair', labelImg: 'barber' },
  {
    key: 'makeup-artist',
    label: 'Beauty Space',
    metaLabel: 'Beauty Space',
    labelImg: 'makeupArtist',
  },
  {
    key: 'nail-technician',
    label: 'Nail Station',
    metaLabel: 'Nail Station',
    labelImg: 'nailTechnician',
  },
  { key: 'cosmetics', label: 'Beauty Room', metaLabel: 'Beauty Room', labelImg: 'cosmetics' },
  { key: 'aesthetics', label: 'Aesthetics', metaLabel: 'Aesthetics Space', labelImg: 'aesthetics' },
  {
    key: 'tattoo-and-piercing',
    label: 'Tattoo & Piercing',
    metaLabel: 'Tattoo & Piercing Space',
    labelImg: 'tattooAndPiercing',
  },
];
export const hairAndBeautyAmenitiesOptions = [
  { key: 'backwash-chair', label: 'Backwash Chair' },
  { key: 'barber-accessories', label: 'Barber Accessories' },
  { key: 'barber-chair', label: 'Barber Chair' },
  { key: 'barber-unit', label: 'Barber Unit' },
  { key: 'capes-gowns-collars', label: 'Capes / Gowns / Collars' },
  { key: 'hb-cleaning-supplies', label: 'Cleaning Supplies' },
  { key: 'hb-client-refreshments', label: 'Client Refreshments' },
  { key: 'hair-products', label: 'Hair Products' },
  { key: 'hairdryer', label: 'Hairdryer' },
  { key: 'locked-cupboards', label: 'Locked cupboards' },
  { key: 'hb-massage-chair', label: 'Massage chair' },
  { key: 'mirror-styling-unit', label: 'Mirror / Styling Unit' },
  { key: 'pedicure-chair', label: 'Pedicure Chair' },
  { key: 'hb-sterilising-equipment', label: 'Sterilising Equipment' },
  { key: 'styling-accessories', label: 'Styling Accessories' },
  { key: 'styling-chair', label: 'Styling Chair' },
  { key: 'hb-treatment-table', label: 'Treatment Table' },
  { key: 'hb-trolley', label: 'Trolley' },
  { key: 'led-magnifying-light', label: 'UV / LED Light' },
  { key: 'hb-waiting-area', label: 'Waiting area' },
  { key: 'washing-point', label: 'Washing point / Basin' },
  { key: 'hb-wifi', label: 'WiFi' },
  { key: 'bench', label: 'Bench' },
  { key: 'clinical-waste-removal', label: 'Clinical Waste Removal' },
  { key: 'piercing-equipment', label: 'Piercing Equipment ' },
  { key: 'sharps-collection', label: 'Sharps Collection' },
  { key: 'stool-tattoo', label: 'Stool Tattoo' },
  { key: 'equipment', label: 'Equipment' },
  { key: 'fitting-room', label: 'Fitting Room' },
  { key: 'hair-straighteners', label: 'Hair straighteners' },
];

export const wellnessOptions = [
  {
    key: 'therapy-room',
    label: 'Therapy Room',
    metaLabel: 'Therapy Room',
    labelImg: 'therapyRoom',
  },
  {
    key: 'massage-room',
    label: 'Massage Room',
    metaLabel: 'Massage Room',
    labelImg: 'massageRoom',
  },
  {
    key: 'clinical-room',
    label: 'Clinical Room',
    metaLabel: 'Clinical Room',
    labelImg: 'clinicalRoom',
  },
];
export const wellnessAmenitiesOptions = [
  { key: 'fw-adjustable-lighting', label: 'Adjustable Lighting' },
  { key: 'fw-changing-area', label: 'Changing Area' },
  { key: 'fw-cleaning-supplies', label: 'Cleaning Supplies' },
  { key: 'fw-client-refreshments', label: 'Client Refreshments' },
  { key: 'couch-armchairs', label: 'Couch / Armchairs' },
  { key: 'fw-massage-chair', label: 'Massage Chair' },
  { key: 'massage-table', label: 'Massage Table' },
  { key: 'w-secure-lockers-(for-user-clients)', label: 'Secure lockers (for clients)' },
  { key: 'w-speakers-music', label: 'Speakers / Music' },
  { key: 'fw-storage', label: 'Storage' },
  { key: 'towels', label: 'Towels' },
  { key: 'fw-trolleys', label: 'Trolleys' },
  { key: 'fw-waiting-area', label: 'Waiting Area' },
  { key: 'fw-treatment-table', label: 'Treatment Table' },
  { key: 'hypnotherapy-chair', label: 'Hypnotherapy Chair' },
  { key: 'suitable-for-talking-therapies', label: 'Suitable for Talking Therapies' },
  {
    key: 'adjustable-examination-treatment-couch',
    label: 'Adjustable Examination / Treatment Couch',
  },
  { key: 'acupuncture-facilities', label: 'Acupuncture facilities' },
  { key: 'consultants-desk-chair', label: "Consultant's Desk / Chair" },
  { key: 'patient-chair', label: 'Patient Chair' },
  { key: 'medical-sink', label: 'Medical Sink' },
  { key: 'couch-roll', label: 'Couch Roll' },
  { key: 'examination-lamp', label: 'Examination Lamp' },
  { key: 'gloves', label: 'Gloves' },
  { key: 'aprons', label: 'Aprons' },
];

export const fitnessOptions = [
  { key: 'fitness', label: 'Fitness Studio', metaLabel: 'Fitness Studio', labelImg: 'fitness' },
  { key: 'yoga-studio', label: 'Yoga Studio', metaLabel: 'Yoga Studio', labelImg: 'yogaStudio' },
  {
    key: 'dance-studio',
    label: 'Dance Studio',
    metaLabel: 'Dance Studio',
    labelImg: 'danceStudio',
  },
  { key: 'sports-hall', label: 'Sports Hall', metaLabel: 'Sports Hall', labelImg: 'sportsHall' },
  {
    key: 'outdoor-sport-space',
    label: 'Outdoor Sport Space',
    metaLabel: 'Outdoor Sport Space',
    labelImg: 'outdoorSportSpace',
  },
  {
    key: 'activity-room',
    label: 'Activity Room',
    metaLabel: 'Activity Room',
    labelImg: 'activityRoom',
  },
];
export const fitnessAmenitiesOptions = [
  { key: 'exercise-mats', label: 'Exercise Mats' },
  { key: 'fitness-equipment', label: 'Fitness equipments' },
  { key: 'free-weights', label: 'Free weights' },
  { key: 'gym-equipment', label: 'Gym Equipment' },
  { key: 'mirrored-wall', label: 'Mirrored Wall' },
  { key: 'refreshments-available', label: 'Refreshments available' },
  { key: 'f-secure-lockers-(for-user-clients)', label: 'Secure locker (for clients)' },
  { key: 'f-speakers-music', label: 'Speakers / Music' },
  { key: 'yoga-mats', label: 'Yoga mats' },
  { key: 'yoga-props', label: 'Yoga props' },
  { key: 'water-point', label: 'Water Point' },
  { key: 'boxing-equipment', label: 'Boxing Equipment' },
  { key: 'martial-arts-equipment', label: 'Martial Arts equipment' },
  { key: 'dance-equipment', label: 'Dance equipment' },
  { key: 'showers', label: 'Showers' },
  { key: 'pool', label: 'Pool' },
  { key: 'cross-training-capability', label: 'Cross training Capability' },
  { key: 'trainers-available', label: 'Trainers Available' },
  { key: 'changing-area', label: 'Changing Area' },
  { key: 'climbing-equipment', label: 'Climbing equipment' },
];

export const eventsAndVenuesOptions = [
  { key: 'music-venue', label: 'Music Venue', metaLabel: 'Music Venue', labelImg: 'musicVenue' },
  {
    key: 'private-event-space',
    label: 'Private Event Space',
    metaLabel: 'Private Event Space',
    labelImg: 'privateEventSpace',
  },
  {
    key: 'sports-venue',
    label: 'Sports Venue',
    metaLabel: 'Sports Venue',
    labelImg: 'sportsVenue',
  },
  {
    key: 'conference-exhibition',
    label: 'Conference & Exhibition ',
    metaLabel: 'Conference & Exhibition space',
    labelImg: 'conferenceExhibition',
  },
  {
    key: 'outdoor-events',
    label: 'Outdoor Events',
    metaLabel: 'Outdoor Event Space',
    labelImg: 'outdoorEvents',
  },
  {
    key: 'private-dining',
    label: 'Private Dining',
    metaLabel: 'Private Dining Space',
    labelImg: 'privateDining',
  },
];
export const eventsAndVenuesAmenitiesOptions = [
  { key: 'bar-hire', label: 'Bar Hire' },
  { key: 'catering-available', label: 'Catering Available' },
  { key: 'reception', label: 'Reception' },
  { key: 'cloakroom', label: 'Cloakroom' },
  { key: 'seating', label: 'Seating' },
  { key: 'tables', label: 'Tables' },
  { key: 'licensed', label: 'Licensed' },
  { key: 'e-dj-equipment', label: 'DJ Booth / Equipment' },
  { key: 'av-equipment', label: 'A/V Equipment' },
  { key: 'e-sound-engineer', label: 'Sound Engineer' },
  { key: 'staff', label: 'Staff' },
  { key: 'stage', label: 'Stage' },
  { key: 'ceremony-location', label: 'Ceremony location' },
  { key: 'ek-props', label: 'Props' },
  { key: 'sound-system', label: 'Sound system' },
  { key: 'screen-projector', label: 'Screen/projector' },
  { key: 'bring-your-own-dj', label: 'Bring your own DJ' },
  { key: 'own-music-allowed', label: 'Own music allowed' },
];

export const coworkingOptions = [
  {
    key: 'desk-space',
    label: 'Coworking Space',
    metaLabel: 'Coworking Space',
    labelImg: 'deskSpace',
  },
  {
    key: 'office-space',
    label: 'Private Office Space',
    metaLabel: 'Private Office Space',
    labelImg: 'officeSpace',
  },
  {
    key: 'meeting-room-space',
    label: 'Meeting Room Space',
    metaLabel: 'Meeting Room Space',
    labelImg: 'meetingRoomSpace',
  },
  {
    key: 'conference-room',
    label: 'Conference Room',
    metaLabel: 'Conference Room',
    labelImg: 'conferenceRoom',
  },
  { key: 'classroom', label: 'Classroom', metaLabel: 'Classroom', labelImg: 'classroom' },
];
export const coworkingAmenitiesOptions = [
  { key: 'bike-storage', label: 'Bike Storage' },
  { key: 'breakout-space', label: 'Breakout Space' },
  { key: 'childcare', label: 'Childcare' },
  { key: 'cleaning', label: 'Cleaning' },
  { key: 'ethernet-connection', label: 'Ethernet connection' },
  { key: 'lockers', label: 'Lockers' },
  { key: 'mailing-address', label: 'Mailing Address' },
  { key: 'meeting-room-access', label: 'Meeting Rooms' },
  { key: 'parking', label: 'Parking' },
  { key: 'cw-pets-allowed', label: 'Pets Allowed' },
  { key: 'printing', label: 'Printing' },
  { key: 'private-phone-booths', label: 'Private phone booths' },
  { key: 'c-showers', label: 'Showers' },
  { key: 'c-flipchart', label: 'Flipchart' },
  { key: 'whiteboards', label: 'Whiteboards' },
  { key: 'conference-call-facilities', label: 'Conference Call Facilities' },
  { key: 'stationary', label: 'Stationary' },
];

export const musicAndArtsOptions = [
  {
    key: 'music-studio',
    label: 'Music & Podcast Studio',
    metaLabel: 'Music & Podcast Studio',
    labelImg: 'musicStudio',
  },
  {
    key: 'recording-studio',
    label: 'Recording Studio',
    metaLabel: 'Recording Studio',
    labelImg: 'recordingStudio',
  },
  {
    key: 'gallery-space',
    label: 'Gallery Space',
    metaLabel: 'Gallery Space',
    labelImg: 'gallerySpace',
  },
  { key: 'art-studio', label: 'Art Studio', metaLabel: 'Art Studio', labelImg: 'artStudio' },
  {
    key: 'rehearsal-space',
    label: 'Rehearsal Space',
    metaLabel: 'Rehearsal Space',
    labelImg: 'rehearsalSpace',
  },
  {
    key: 'drama-studio',
    label: 'Drama Studio',
    metaLabel: 'Drama Studio',
    labelImg: 'dramaStudio',
  },
  {
    key: 'theatre-space',
    label: 'Theatre Space',
    metaLabel: 'Theatre Space',
    labelImg: 'theatreSpace',
  },
];
export const musicAndArtsAmenitiesOptions = [
  { key: 'm-air-conditioning', label: 'Air Conditioning' },
  { key: 'dj_equipment', label: 'DJ Equipment' },
  { key: 'm-clothes-rail', label: 'Clothes Rail' },
  { key: 'm-flipchart', label: 'Flipchart' },
  { key: 'm-high-ceilings', label: 'High Ceilings' },
  { key: 'microphones', label: 'Microphones' },
  { key: 'mixer', label: 'Mixer' },
  { key: 'cs-speakers-music', label: 'Music / Speaker' },
  { key: 'pa-speakers', label: 'PA Speakers' },
  { key: 'm-polyboards-and-stands', label: 'Polyboards and Stands' },
  { key: 'projector', label: 'Projector' },
  { key: 'm-refreshments', label: 'Refreshments' },
  { key: 'm-seating-area', label: 'Seating area' },
  { key: 'cs-sound-engineer', label: 'Sound Engineer' },
  { key: 'm-storage-space', label: 'Storage Space' },
  { key: 'm-tables-and-chairs', label: 'Tables and Chairs' },
  { key: 'white-boards', label: 'Whiteboards' },
  { key: 'easels', label: 'Easels' },
  { key: 'acoustic-panels', label: 'Acoustic Panels' },
  { key: 'gapped-walls', label: 'Gapped Walls' },
  { key: 'sound-curfew', label: 'Sound Curfew' },
  { key: 'carpeted-surfaces', label: 'Carpeted Surfaces' },
  { key: 'bass-traps', label: 'Bass traps' },
  { key: 'boomy-acoustics', label: 'Boomy acoustics' },
  { key: 'dry-acoustics', label: 'Dry acoustics' },
  { key: 'amps-allowed', label: 'Amps allowed' },
  { key: 'm-soundproof', label: 'Soundproof' },
];

export const photographyAndFilmOptions = [
  {
    key: 'photography',
    label: 'Photography Studio',
    metaLabel: 'Photography Studio',
    labelImg: 'photography',
  },
  {
    key: 'location-shoot',
    label: 'Shoot Location',
    metaLabel: 'Shoot Location',
    labelImg: 'locationShoot',
  },
  {
    key: 'outdoor-site',
    label: 'Outdoor Site',
    metaLabel: 'Outdoor Site',
    labelImg: 'outdoorSite',
  },
];
export const photographyAndFilmAmenitiesOptions = [
  { key: 'adjustable-lighting', label: 'Adjustable Lighting' },
  { key: 'pf-air-conditioning', label: 'Air Conditioning' },
  { key: 'backdrops-white-black-green)', label: 'Backdrops (White, Black, Green)' },
  { key: 'background-reflectors', label: 'Background Reflectors' },
  { key: 'blackout-option', label: 'Blackout Option' },
  { key: 'pf-clothes-rail', label: 'Clothes Rail' },
  { key: 'pf-high-ceilings', label: 'High Ceilings' },
  { key: 'lighting-equipment', label: 'Lighting Equipment' },
  { key: 'mirror-and-makeup-desk', label: 'Mirror and Makeup Desk' },
  { key: 'natural-light', label: 'Natural Light' },
  { key: 'pf-polyboards-and-stands', label: 'Polyboards and Stands' },
  { key: 'projector', label: 'Projector' },
  { key: 'pf-refreshments', label: 'Refreshments' },
  { key: 'pf-seating-area', label: 'Seating area' },
  { key: 'separate-changing-area', label: 'Separate changing area' },
  { key: 'pf-storage-space', label: 'Storage Space' },
  { key: 'pf-tables-and-chairs', label: 'Tables and Chairs' },
  { key: 'white-walls', label: 'White walls' },
  { key: 'sound-stage', label: 'Sound stage' },
  { key: 'fluorescent-light', label: 'Fluorescent Light' },
  { key: 'halogen-light', label: 'Halogen light' },
  { key: 'tungsten-light', label: 'Tungsten Light' },
  { key: 'hmi-light', label: 'HMI Light' },
  { key: 'grid-lights', label: 'Grid Lights' },
  { key: 'house-lights', label: 'House Lights' },
  { key: 'brick-walls', label: 'Brick walls' },
  { key: 'painted-brick-walls', label: 'Painted Brick walls' },
  { key: 'tiled-walls', label: 'Tiled walls' },
  { key: 'concrete-walls', label: 'Concrete walls' },
  { key: 'wood-panelling', label: 'Wood panelling' },
  { key: 'infinity-cove', label: 'Infinity Cove' },
  { key: 'pf-fitting-room', label: 'Fitting Room' },
  { key: 'seamless', label: 'Seamless' },
  { key: 'rooftop', label: 'Rooftop' },
  { key: 'outdoor-access', label: 'Outdoor Access' },
  { key: 'shooting-kitchen', label: 'Shooting Kitchen' },
  { key: 'cityscape-skyline-views', label: 'Cityscape/Skyline Views' },
  { key: 'pf-soundproof', label: 'Soundproof' },
  { key: 'cyclorama', label: 'Cyclorama' },
  { key: 'one-room', label: 'One room' },
  { key: 'multi-room', label: 'Multi-room' },
  { key: 'overhead-shooting', label: 'Overhead Shooting' },
];

export const kitchenOptions = [
  {
    key: 'kitchen-space',
    label: 'Kitchen Space',
    metaLabel: 'Kitchen Space',
    labelImg: 'kitchenSpace',
  },
  { key: 'pop-up-space', label: 'Pop-up Space', metaLabel: 'Pop-up Space', labelImg: 'popUpSpace' },
];
export const kitchenAmenitiesOptions = [
  { key: '3-phase-power', label: '3 Phase Power' },
  { key: 'blender', label: 'Blender' },
  { key: 'chest-freezer', label: 'Chest Freezer' },
  { key: 'chopping-board', label: 'Chopping Board' },
  { key: 'combi-oven', label: 'Combi Oven' },
  { key: 'commercial-oven', label: 'Commercial oven' },
  { key: 'counter-space', label: 'Counter Space' },
  { key: 'dishwasher', label: 'Dishwasher' },
  { key: 'dry-storage', label: 'Dry Storage' },
  { key: 'extractor-fan', label: 'Extractor Fan' },
  { key: 'food-processor', label: 'Food Processor' },
  { key: 'freezer', label: 'Freezer' },
  { key: 'fridge', label: 'Fridge' },
  { key: 'gas-hob', label: 'Gas Hob' },
  { key: 'grill', label: 'Grill' },
  { key: 'induction-hob', label: 'Induction Hob' },
  { key: 'microwave', label: 'Microwave' },
  { key: 'pots-pans', label: 'Pots and Pans' },
  { key: 'sink', label: 'Sink' },
  { key: 'stand-mixer', label: 'Stand Mixer' },
  { key: 'walkin-fridge', label: 'Walk-in Fridge' },
  { key: 'fridge-freezer', label: 'Fridge / Freezer' },
  { key: 'kettle', label: 'Kettle' },
  { key: 'tea', label: 'Tea' },
  { key: 'coffee', label: 'Coffee' },
  { key: 'filtered-water', label: 'Filtered Water' },
  { key: 'tap-water', label: 'Tap Water' },
  { key: 'dark-kitchen', label: 'Dark Kitchen' },
];
