export const _interestedPatches = [
  {
    id: 'Hair & Beauty',
    name: 'interestedPatches',
  },
  {
    id: 'Fitness',
    name: 'interestedPatches',
  },
  {
    id: 'Coworking & Office',
    name: 'interestedPatches',
  },
  {
    id: 'Photography & Film',
    name: 'interestedPatches',
  },
  {
    id: 'Wellness',
    name: 'interestedPatches',
  },
  {
    id: 'Events & Venues',
    name: 'interestedPatches',
  },
  {
    id: 'Music & Arts',
    name: 'interestedPatches',
  },
  {
    id: 'Kitchen & Popups',
    name: 'interestedPatches',
  },
];
